import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import logoUrl from '../../../static/images/logo-branco-fundo-azul.png';

interface Props {
  description?: string;
  title?: string;
  canonical?: string;
  children?: React.ReactNode;
}

const url = process.env.GATSBY_HOST_URL || '';

const Seo = ({ description, title, children, canonical }: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            social {
              twitter
            }
          }
        }
      }
    `
  );

  const defaultTitle = site.siteMetadata?.title;
  const metaDescription = description || site.siteMetadata.description;
  const currentTitle = title ? `${defaultTitle} - ${title}` : defaultTitle;

  return (
    <>
      <title>{currentTitle}</title>
      <meta name="description" content={metaDescription} />

      <meta property="og:title" content={currentTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={url + logoUrl} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />

      {site.siteMetadata?.social?.twitter && (
        <meta
          name="twitter:creator"
          content={site.siteMetadata.social.twitter}
        />
      )}

      {typeof canonical === 'string' && (
        <link rel="canonical" href={site.siteMetadata.siteUrl + canonical} />
      )}

      {children}
    </>
  );
};

export default Seo;
